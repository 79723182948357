import { Text, View } from 'react-native';
import { Dimensions, Platform, ScrollView as ScrollViewRN } from 'react-native';
import { useRef } from 'react';
import { ScrollView } from 'tamagui';
import useIsPhone from '@hooks/useIsPhone';

interface IFeature {
  title: string;
  text: string;
}

interface IMCFContent {
  title: string;
  text: string;
  howItWorks?: IFeature;
  goodToKnow?: IFeature;
  notCovered?: IFeature;
  link?: string;
  contentAfterLink?: string;
}

interface MCFContentProps {
  content: IMCFContent;
  onPressLink?: () => void;
}

const MCFContent = ({ content, onPressLink }: MCFContentProps) => {
  const isPhone = useIsPhone();
  const scrollViewRef = useRef<ScrollViewRN | null>(null);
  return (
    <ScrollView
      contentContainerStyle={{
        marginBottom: isPhone ? 120 : 0,
        justifyContent: 'space-between',
        paddingTop: 5,
        paddingBottom: 60,
      }}
      style={
        Platform.OS === 'web' && {
          height: isPhone ? Dimensions.get('screen').height - 372 : '100%',
          flexGrow: 1,
        }
      }
      showsVerticalScrollIndicator={false}
      ref={scrollViewRef}
    >
      <View style={{ padding: 16 }}>
        <Text
          style={{
            color: '#666C7B',
            fontSize: 14,
            fontWeight: '400',
            lineHeight: 20,
            fontFamily: 'Inter-Regular, sans-serif',
          }}
        >
          {content.text}
          {content.link && (
            <Text
              style={{
                color: '#0073C4',
                fontSize: 14,
                fontWeight: '400',
                lineHeight: 20,
                height: 20,
              }}
              onPress={() => onPressLink && onPressLink()}
            >
              {content.link}
            </Text>
          )}
          {content.contentAfterLink && (
            <Text
              style={{
                color: '#666C7B',
                fontSize: 14,
                fontWeight: '400',
                lineHeight: 20,
              }}
            >
              {content.contentAfterLink}
            </Text>
          )}
        </Text>
      </View>
    </ScrollView>
  );
};

export default MCFContent;
