import { ErrorView } from '@views/Common';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import { useSelector } from 'react-redux';
import { selectErrorStatesPage } from '@store/features/errorStates/selectors';
import { ErrorPageProps } from '@navigation/RootNavigator/RootNavigator.types';
import { useMemo } from 'react';
import { useSideModal } from '@hooks/useSideModal';
import useIsPhone from '@hooks/useIsPhone';
import { sideModalNames } from '@constants/sideModalNames';
import { Platform } from 'react-native';
import RNRestart from 'react-native-restart';
import { useAppDispatch } from '@store/hooks';
import { errorStatesActions } from '@store/features/errorStates/slice';

const ErrorPage = ({ navigation, route }: ErrorPageProps) => {
  const dispatch = useAppDispatch();
  // useEffect(() => {
  //   dispatch(errorStatesActions.resetError());
  // }, []); //TODO: research why we had this logic
  const isPhone = useIsPhone();

  const { isModalOpen, modalName, toOpenModal, toCloseModal } = useSideModal({
    route,
    navigation,
  });

  const navigateBack = () => {
    dispatch(errorStatesActions.resetError());
    navigation.goBack();
  };
  const navigateToSupport = () => {
    dispatch(errorStatesActions.resetError());
    if (isPhone) {
      navigation.navigate('HomeNavigator', {
        screen: 'Support',
        params: {
          pageName: 'customerService',
        },
      });
    } else {
      toOpenModal(sideModalNames.customerService)();
    }
  };
  const tryAgainHandler = () => {
    if (Platform.OS === 'web') {
      window.location.reload();
    } else {
      RNRestart.restart();
    }
  };
  const navigateToLogin = () => {
    dispatch(errorStatesActions.resetError());
    navigation.navigate('AuthNavigator', {
      screen: 'PaychexLoginPage',
    });
  };
  const pageContent = useSelector(selectErrorStatesPage);

  const mainButtonHandler = useMemo(() => {
    if (pageContent.mainButtonAction === 'retry') return tryAgainHandler;
    if (pageContent.mainButtonAction === 'goBack') return navigateBack;
    if (pageContent.mainButtonAction === 'login') return navigateToLogin;
  }, [pageContent]);

  const ghostButtonHandler = useMemo(() => {
    if (pageContent.ghostButtonAction === 'retry') return tryAgainHandler;
    if (pageContent.ghostButtonAction === 'contactSupport')
      return navigateToSupport;
  }, [pageContent]);

  const handleNavigateToHomePage = () => {
    toCloseModal();
    navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
  };

  return (
    <MediaLayoutTemplate
      Mobile={ErrorView.mobile}
      Desktop={ErrorView.desktop}
      pageContent={pageContent}
      mainButtonHandler={mainButtonHandler}
      ghostButtonHandler={ghostButtonHandler}
      toCloseModal={handleNavigateToHomePage}
      isModalOpen={isModalOpen}
      modalName={modalName}
    />
  );
};

export default ErrorPage;
