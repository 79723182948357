import { SelectAccountProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import { SelectAccountView } from '@views/Transfers';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectAccountsCategories } from '@store/features/cards/selectors';
import externalBankAccountsThunks from '@store/features/externalBankAccounts/asyncThunks';
import {
  selectSavingsAccountId,
  selectSpendingAccountId,
} from '@store/features/cardholder/selectors';
import { cloneDeep } from 'lodash';
import { useAppDispatch } from '@store/hooks';
import { ICategoryAccount } from '@devTypes/accountTypes';
import { tracker } from '../../../eventCollector';
import { application, operations } from '@constants/traceability';

const SelectAccountPage = ({ navigation, route }: SelectAccountProps) => {
  const navigateBack = () => {
    navigation.goBack();
  };
  const prevScreen: any = useMemo(() => {
    const routes = navigation.getState().routes;
    return routes[routes.length - 2];
  }, [navigation]);
  const selectAccountType = useMemo(() => route.params.selectAccount, [route]);
  const selectAccount = (id: string) => {
    navigation.navigate('HomeNavigator', {
      screen: prevScreen.name,
      params:
        selectAccountType === 'From'
          ? // @ts-ignore
            { ...prevScreen.params, fromAccountId: id }
          : { ...prevScreen.params, toAccountId: id },
    });
  };

  const navigateToConnectAccount = () => {
    navigation.navigate('ConnectAccount');
  };

  const navigateToDeleteAccount = (account: ICategoryAccount) => {
    navigation.navigate('DeleteAccount', { account });
  };

  const allAccounts = useSelector(selectAccountsCategories);
  const spendingAccountId = useSelector(selectSpendingAccountId);
  const savingAccountId = useSelector(selectSavingsAccountId);

  const filteredAccounts = useMemo(() => {
    const currentAccounts = cloneDeep(allAccounts);

    const filterExternalAccounts = () => {
      currentAccounts.spending.accounts = currentAccounts.spending.accounts.map(
        (acc, index) => ({
          ...acc,
          isDisabled: !!index,
        })
      );
      currentAccounts.saving.accounts = currentAccounts.saving.accounts.map(
        (acc, index) => ({
          ...acc,
          isDisabled: !!index || !savingAccountId,
        })
      );
    };

    const filterPaychexPayAccounts = () => {
      currentAccounts.spending.accounts[0].isDisabled = true;
      if (savingAccountId) {
        currentAccounts.saving.accounts[0].isDisabled = true;
      }
    };

    if (selectAccountType === 'To') {
      if (
        prevScreen?.params?.fromAccountId === spendingAccountId ||
        prevScreen?.params?.fromAccountId === savingAccountId
      ) {
        filterPaychexPayAccounts();
      } else if (prevScreen?.params?.fromAccountId) {
        filterExternalAccounts();
      }
    } else {
      if (
        prevScreen?.params?.toAccountId === spendingAccountId ||
        prevScreen?.params?.toAccountId === savingAccountId
      ) {
        filterPaychexPayAccounts();
      } else if (prevScreen?.params?.toAccountId) {
        filterExternalAccounts();
      }
    }

    return currentAccounts;
  }, [allAccounts]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    const timer = tracker.start('Select Accounts: Fetch External Accounts');
    const txid = tracker.uuid();
    const bizpn = application.bizpnPrefix + operations.ExternalAccount;
    const comp = application.compPrefix + operations.ExternalAccount;
    dispatch(
      externalBankAccountsThunks.getExternalAccounts({
        additionalHeaders: {
          'x-payx-txid': txid || tracker.uuid(),
          'x-payx-bizpn': bizpn,
          'x-payx-comp': comp,
        },
      })
    ).then(() => {
      timer({
        txid: txid,
        bizpn: bizpn,
      });
    });
  }, []);

  return (
    <SelectAccountView
      navigateBack={navigateBack}
      selectAccountType={selectAccountType}
      selectAccount={selectAccount}
      accounts={filteredAccounts}
      navigateToConnectAccount={navigateToConnectAccount}
      navigateToDeleteAccount={navigateToDeleteAccount}
    />
  );
};

export default SelectAccountPage;
