import { Center } from 'native-base';
import {
  HeaderMobileTemplate,
  SideBarTemplate,
  SwiperDesktopWrapper,
} from '@templates';
import { TPressable, Text } from '@atoms';
import MenuArrow from '../../../assets/menuArrow.svg';
import { helpScreensNames } from '@constants/helpScreensNames';
import ComponentsListProvider from '@utils/ComponentsListProvider';
import { IHelpMenuItem } from '@hooks/useHelp';
import {
  FaqContent,
  OnboardingSwiper,
  SendFeedbackContent,
  SupportScreenContent,
} from '@organisms';
import faq from '@constants/faq';
import SUPPORT_PAGES from '@constants/support';
import ONBOARDING_SLIDES from '@constants/onboardingSlides';
import { MobileWebHeader, SideModal } from '@molecules';
import React from 'react';
import { sideModalNames } from '@constants/sideModalNames';
import { Platform } from 'react-native';
import { ScrollView, Separator, View, XStack } from 'tamagui';

interface HelpViewMobileProps {
  navigateBack: () => void;
  menuItems: IHelpMenuItem[];
}

interface HelpViewDesktopProps {
  screenName: string;
  onPressSkip: () => void;
  onPressNext: () => void;
  onPressPrev: () => void;
  index: number;
  setIndex: (index: number) => void;
  isModalOpen: boolean;
  toCloseModal: () => void;
  modalName: string;
  onSendFeedback: () => void;
  navigationMap: (link: string) => void;
}

const HelpMenuItem = ({
  title,
  handler,
  testId,
}: {
  title: string;
  handler: () => void;
  testId: string;
}) => {
  return (
    <TPressable px={'$4'} onPress={handler} testID={testId}>
      <XStack paddingVertical={21} justifyContent="space-between">
        <Text variant="regular">{title}</Text>
        <MenuArrow />
      </XStack>
      <Separator borderColor="$gray20" />
    </TPressable>
  );
};

const mobile = ({ navigateBack, menuItems }: HelpViewMobileProps) => {
  return (
    <>
      {Platform.OS === 'web' && <MobileWebHeader />}

      <HeaderMobileTemplate
        header={{
          title: 'Help',
          isBackButton: true,
          onPressBack: navigateBack,
        }}
      >
        <ScrollView showsVerticalScrollIndicator={false}>
          {menuItems.map((item, index) => (
            <HelpMenuItem {...item} key={index} />
          ))}
        </ScrollView>
      </HeaderMobileTemplate>
    </>
  );
};

const desktop = ({
  screenName,
  onPressSkip,
  onPressNext,
  onPressPrev,
  index,
  setIndex,
  isModalOpen,
  toCloseModal,
  modalName,
  onSendFeedback,
  navigationMap,
}: HelpViewDesktopProps) => {
  return (
    <>
      <SideBarTemplate>
        <ComponentsListProvider
          components={{
            [helpScreensNames.customerService]: (
              <Center flexGrow={1}>
                <View bg="white" w="460px" borderRadius={12}>
                  <SupportScreenContent
                    {...SUPPORT_PAGES.customerService}
                    isSmallSpaces
                  />
                </View>
              </Center>
            ),
            [helpScreensNames.faq]: (
              <View paddingVertical={5}>
                <Center flexGrow={1}>
                  <View w="460px" p="20px" bg="white" borderRadius={12}>
                    <Text
                      textAlign="center"
                      paddingBottom={20}
                      variant="title"
                      testID={'paychexpay.helpView.faqTitle'}
                    >
                      FAQs
                    </Text>
                    <FaqContent faqData={faq} navigationMap={navigationMap} />
                  </View>
                </Center>
              </View>
            ),
            [helpScreensNames.getStartedWizard]: (
              <Center flexGrow={1} bgColor="white">
                <View
                  bg="white"
                  borderRadius={12}
                  w="670px"
                  h="775px"
                  overflow="hidden"
                >
                  <SwiperDesktopWrapper
                    index={index}
                    onPressNext={onPressNext}
                    onPressPrev={onPressPrev}
                    onPressSkip={onPressSkip}
                    isModal={false}
                  >
                    <OnboardingSwiper index={index} setIndex={setIndex} />
                  </SwiperDesktopWrapper>
                </View>
              </Center>
            ),
            [helpScreensNames.feedback]: (
              <Center flexGrow={1}>
                <View w="460px" p="20px" bg="white" borderRadius={12}>
                  <SendFeedbackContent onSendFeedback={onSendFeedback} />
                </View>
              </Center>
            ),
          }}
          componentsName={screenName}
        />
      </SideBarTemplate>
      <SideModal isModalOpen={isModalOpen} onPressModalClose={toCloseModal}>
        <ComponentsListProvider
          componentsName={modalName}
          components={{
            [sideModalNames.aboutPaidEarly]: (
              <View px="80px" pt={8}>
                <Text variant="subtitle" fontSize={11}>
                  {ONBOARDING_SLIDES.getPaidEarly.help}
                </Text>
              </View>
            ),
            [sideModalNames.cardLost]: (
              <SupportScreenContent {...SUPPORT_PAGES.cardLost} />
            ),
            [sideModalNames.customerService]: (
              <SupportScreenContent {...SUPPORT_PAGES.customerService} />
            ),
            [sideModalNames.disputeCharge]: (
              <SupportScreenContent {...SUPPORT_PAGES.disputeCharge} />
            ),
          }}
        />
      </SideModal>
    </>
  );
};

const HelpView = {
  mobile,
  desktop,
};

export default HelpView;
