const ONBOARDING_SLIDES = {
  getPaidEarly: {
    title: 'Get paid up to 2 days early*',
    description:
      '*In order for you to be paid early, your payroll or benefits payment provider must submit the deposit early. Your payment provider may not submit the deposit early each payment period. Be sure to ask when they submit your deposit information to the bank for processing. \n' +
      '\n' +
      'Early deposit of funds begins upon the 2nd qualifying deposit. A qualifying deposit is defined as a direct deposit greater than $5.00 received from the same payer. \n',
    help:
      '*In order for you to be paid early, your payroll or benefits payment provider must submit the deposit early. Your payment provider may not submit the deposit early each payment period. Be sure to ask when they submit your deposit information to the bank for processing.  \n' +
      '\n' +
      'Early deposit of funds begins upon the 2nd qualifying deposit. A qualifying deposit is defined as a direct deposit greater than $5.00 received from the same payer.',
  },
  gradCash: {
    title: 'Get cash on the go',
    subtitle: 'Withdraw fee-free at nearly 40,000 MoneyPass® ATMs nationwide.',
  },
  enjoyMastercard: {
    title: 'Enjoy Mastercard® benefits',
    subtitle:
      'Here are some features you can now access:\n' +
      '• Zero Liability Protection\n' +
      '• Mastercard ID Theft Protection™\n' +
      '• Mastercard Airport Concierge™',
  },
  dda: {
    title: 'Ready to set up direct deposit?',
    subtitle:
      'Receive your funds early by depositing them directly into your Spending Account.',
    description:
      '*In order for you to be paid early, your payroll or benefits payment provider must submit the deposit early. Your payment provider may not submit the deposit early each payment period. Be sure to ask when they submit your deposit information to the bank for processing. \n' +
      '\n' +
      'Early deposit of funds begins upon the 2nd qualifying deposit. A qualifying deposit is defined as a direct deposit greater than $5.00 received from the same payer.',
  },
};

export const slidesArrayLength = 2;

export default ONBOARDING_SLIDES;
