import { HeaderAndFooterMobileTemplate } from '@templates';
import { TButton } from '@atoms';
import { ConnectAccountContent } from '@organisms';
import { Control } from 'react-hook-form';
import { IConnectAccountFormInputs } from '@organisms/ConnectAccountForm/helper';
import { ScrollView, useWindowDimensions } from 'tamagui';
import { Platform } from 'react-native';
import { getHeightOfToolbarsWeb } from '@utils/getWindowHeightWeb';

interface ConnectAccountViewProps {
  navigateBack: () => void;
  control: Control<IConnectAccountFormInputs>;
  onSubmit: () => void;
  isButtonDisabled: boolean;
  isButtonLoading: boolean;
  isBankAcntNameInputDisabled: boolean;
}

const ConnectAccountView = ({
  navigateBack,
  control,
  onSubmit,
  isButtonLoading,
  isButtonDisabled,
  isBankAcntNameInputDisabled,
}: ConnectAccountViewProps) => {
  const { height: screenHeight } = useWindowDimensions();
  return (
    <HeaderAndFooterMobileTemplate
      header={{
        title: 'Add new account',
        isBackButton: true,
        onPressBack: navigateBack,
      }}
      footerContent={
        <TButton
          onPress={onSubmit}
          disabled={isButtonDisabled}
          isLoading={isButtonLoading}
          testID="paychexpay.transfer.ConnectAccountView.button.addAccountButton"
        >
          Add account
        </TButton>
      }
    >
      <ScrollView
        keyboardShouldPersistTaps={'handled'}
        contentContainerStyle={{
          paddingHorizontal: 16,
          paddingTop: 32,
          flexGrow: 1,
        }}
        style={
          Platform.OS === 'web' && {
            height: screenHeight - getHeightOfToolbarsWeb(), // to avoid scroll blocking issue on small size devices
            flexGrow: 1,
          }
        }
      >
        <ConnectAccountContent
          control={control}
          isBankAcntNameInputDisabled={isBankAcntNameInputDisabled}
        />
      </ScrollView>
    </HeaderAndFooterMobileTemplate>
  );
};

export default ConnectAccountView;
