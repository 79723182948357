import { ChangePinProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import {
  selectCardholder,
  selectSpendingAccountId,
} from '@store/features/cardholder/selectors';
import cardsThunks from '@store/features/cards/asyncThunks';
import { useAppDispatch } from '@store/hooks';
import { ChangePinView } from '@views/Accounts/ChangePinView';
import { useSelector } from 'react-redux';

const ChangePinPage = ({ navigation, route }: ChangePinProps) => {
  const navigateBack = () => {
    navigation.goBack();
  };

  const dispatch = useAppDispatch();
  const spendingAccountId = useSelector(selectSpendingAccountId);
  const cardholder = useSelector(selectCardholder);

  const refetchChangePinUrl = async () => {
    await dispatch(
      cardsThunks.changePin({
        card_id: spendingAccountId,
        language_code: 'en',
        cardholder_id: cardholder.cardholder_id,
      })
    );
  };

  return (
    <ChangePinView
      titleText={route.params.titleText}
      changePinUrl={route.params.changePinUrl}
      navigateBack={navigateBack}
      refetchChangePinUrl={refetchChangePinUrl}
    />
  );
};

export default ChangePinPage;
